var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DEFAULT_WAI_ARIA_ATTRIBUTE } from '../type';
export default function Comment(_a) {
    var _b = _a.visible, visible = _b === void 0 ? true : _b, _c = _a.width, width = _c === void 0 ? '80' : _c, _d = _a.height, height = _d === void 0 ? '80' : _d, _e = _a.backgroundColor, backgroundColor = _e === void 0 ? '#ff6d00' : _e, _f = _a.color, color = _f === void 0 ? '#fff' : _f, _g = _a.wrapperClass, wrapperClass = _g === void 0 ? '' : _g, _h = _a.wrapperStyle, wrapperStyle = _h === void 0 ? {} : _h, _j = _a.ariaLabel, ariaLabel = _j === void 0 ? 'comment-loading' : _j;
    return !visible ? null : (React.createElement("svg", __assign({ width: width, height: height, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100", preserveAspectRatio: "xMidYMid", className: wrapperClass, style: wrapperStyle, "aria-label": ariaLabel, "data-testid": 'comment-svg' }, DEFAULT_WAI_ARIA_ATTRIBUTE),
        React.createElement("path", { d: "M78,19H22c-6.6,0-12,5.4-12,12v31c0,6.6,5.4,12,12,12h37.2c0.4,3,1.8,5.6,3.7,7.6c2.4,2.5,5.1,4.1,9.1,4 c-1.4-2.1-2-7.2-2-10.3c0-0.4,0-0.8,0-1.3h8c6.6,0,12-5.4,12-12V31C90,24.4,84.6,19,78,19z", "ng-attr-fill": "{{config.c1}}", fill: backgroundColor }),
        React.createElement("circle", { cx: "30", cy: "47", r: "5", "ng-attr-fill": "{{config.c2}}", fill: color },
            React.createElement("animate", { attributeName: "opacity", calcMode: "linear", values: "0;1;1", keyTimes: "0;0.2;1", dur: "1", begin: "0s", repeatCount: "indefinite" })),
        React.createElement("circle", { cx: "50", cy: "47", r: "5", "ng-attr-fill": "{{config.c3}}", fill: color },
            React.createElement("animate", { attributeName: "opacity", calcMode: "linear", values: "0;0;1;1", keyTimes: "0;0.2;0.4;1", dur: "1", begin: "0s", repeatCount: "indefinite" })),
        React.createElement("circle", { cx: "70", cy: "47", r: "5", "ng-attr-fill": "{{config.c4}}", fill: color },
            React.createElement("animate", { attributeName: "opacity", calcMode: "linear", values: "0;0;1;1", keyTimes: "0;0.4;0.6;1", dur: "1", begin: "0s", repeatCount: "indefinite" }))));
}
