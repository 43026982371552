import Audio from './loader/Audio';
import BallTriangle from './loader/BallTriangle';
import Bars from './loader/Bars';
import Circles from './loader/Circles';
import CirclesWithBar from './loader/CirclesWithBar';
import Grid from './loader/Grid';
import Hearts from './loader/Hearts';
import InfinitySpin from './loader/InfinitySpin';
import LineWave from './loader/LineWave';
import MutatingDots from './loader/MutatingDots';
import Oval from './loader/Oval';
import Puff from './loader/Puff';
import RevolvingDot from './loader/RevolvingDot';
import Rings from './loader/Rings';
import RotatingSquare from './loader/RotatingSquare';
import RotatingLines from './loader/RotatingLines';
import TailSpin from './loader/TailSpin';
import ThreeCircles from './loader/ThreeCircles';
import ThreeDots from './loader/ThreeDots';
import Triangle from './loader/Triangle';
import Watch from './loader/Watch';
import FallingLines from './loader/FallingLines';
import Vortex from './loader/Vortex';
import RotatingTriangles from './loader/RotatingTriangles';
import Radio from './loader/Radio';
import ProgressBar from './loader/ProgressBar';
import MagnifyingGlass from './loader/MagnifyingGlass';
import FidgetSpinner from './loader/FidgetSpinner';
import Dna from './loader/Dna';
import Discuss from './loader/Discuss';
import ColorRing from './loader/ColorRing';
import Comment from './loader/Comment';
import Blocks from './loader/Blocks';
export { Audio, BallTriangle, Bars, Blocks, Circles, CirclesWithBar, ColorRing, Comment, Discuss, Dna, Grid, FallingLines, FidgetSpinner, Hearts, InfinitySpin, LineWave, MagnifyingGlass, MutatingDots, Oval, ProgressBar, Puff, Radio, RevolvingDot, RotatingSquare, RotatingLines, Rings, TailSpin, ThreeDots, ThreeCircles, Triangle, RotatingTriangles, Watch, Vortex, };
